import React from 'react';
import styles from './Gallery.module.scss'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import { getImages } from './ImageData'

export const Gallery = (props) => (
  <div>
    <Container fluid className={styles.gallery}>
      <Row className={styles.Row}>
        {Object.values(getImages()).map(item => (
          <Col xs={6} sm={6} md={4} lg={3} xl={2} className={styles.Col}>
            <Link to={"/images/"+item.link}>
              <img className={styles.galleryimage} src={item.image}></img>        
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  </div>
)

