import React, { useState } from "react";
import {
  Nav,
  Navbar,
  Form,
  FormControl,
  NavDropdown,
  Button,
  Container,
} from "react-bootstrap";
import styles from "./NavigationBar.module.scss";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

export const NavigationBar = () => {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  function closeModal() {
    setShow(false)
  }
  return (
    <div className={styles.navbar1}>
      <Navbar expand="sm">
        <a class="navbar-brand" href="#">
          {" "}
          <Link className={styles.name} to="/">
             susanna byrne 
          </Link>
        </a>
      </Navbar>

      <div className={styles.navbar}>
        <Navbar expand="sm">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav classNameclassName="ml-auto">
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/">
                  {" "}
                  welcome{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/contact">
                  {" "}
                  contact{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/images">
                  {" "}
                  gallery{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/exhibitions">
                  {" "}
                  exhibitions/cv{" "}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className={styles.socialsContainer}>

        <Navbar>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.instagram.com/wilkes.and.weaver"
              target="_blank"
              aria-label="Instagram"
            >
              <GrInstagram />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.twitter.com/susannabyrne"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.linkedin.com/in/susanna-byrne-2394242b"
              target="_blank"
              aria-label="Instagram"
            >
              <FaLinkedin />
            </Nav.Link>
          </Nav.Item>
        </Navbar>
      </div>
      <Navbar className={styles.Modal}>
        <AiOutlineMenu className={styles.Menu} onClick={() => handleShow()} />
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <Link className={styles.name} to="/">
                susanna byrne
              </Link>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className={styles.menuItems}>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/">
                welcome
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/contact">
                contact
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/images">
                gallery
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/exhibitions">
                exhibitions / cv
              </Link>
            </Container>
            <div className={styles.footer}></div>
          </Modal.Body>
          <div className={styles.footer}>
            <div className={styles.Wrap}>
              <div>
                <Container className={styles.footerContainer}>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.instagram.com/wilkes.and.weaver"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <GrInstagram />
                  </a>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.twitter.com/susannabyrne"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.linkedin.com/in/susanna-byrne-2394242b"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaLinkedin />
                  </a>
                </Container>
              </div>
            </div>
          </div>
        </Modal>
      </Navbar>
    </div>
  );
};



// export const NavigationBar = () => (
//     <Styles>
//       <Navbar className={styles.navbar} expand="sm">
//         <Navbar.Toggle aria-controls="basic-navbar-nav"/>
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav classNameclassName="ml-auto">
//             <Nav.Item><Nav.Link href="/">welcome</Nav.Link></Nav.Item> 
//             <Nav.Item><Nav.Link href="/projects">projects</Nav.Link></Nav.Item>
//             <Nav.Item><Nav.Link href="/about">about me</Nav.Link></Nav.Item>
//             <Nav.Item><Nav.Link href="/exhibitions">exhibitions</Nav.Link></Nav.Item>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </Styles>
//   )