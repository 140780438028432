import shadows from './images/shadows.png';
import Print1 from './Print1.png';
import Offord from './Offord.png';
import Memoryscape from './Memoryscape.png';

export const imageData = [
    {
        image: Print1,
        title: "Untitled(Tuile) 2021 | Etching, hand-colouring on paper | 20 x 28cm Variable edition of 15",
      },

      {
        image: Offord,
        title: "Untitled (Offord) 2021 | Etching, hand-colouring on paper | 13 x 18cm Variable edition",
      },

      {
        image: Memoryscape,
        title: "Memoryscape XIII | Photograph",
      },
      
  ];
  
  export function getImages() {
    const res = {};
    for (var f = 0; f < imageData.length; f++) {
      const link = f + "-" + imageData[f].title
      res[link] = {...imageData[f], link}
    }
    return res;
  }

  export function getFirst() {
    const f = 0
    return f + "-" + imageData[f].title
  }
  
  export function getLinks() {
    const images = Object.values(getImages())
    const res = {};
    for (var f = 0; f < images.length; f++) {
      var prev = null;
      var next = null;
      if (f === 0) {
        prev = images[images.length - 1].link;
      } else {
        prev = images[f - 1].link;
      }
      if (f === images.length - 1) {
        next = images[0].link;
      } else {
        next = images[f + 1].link;
      }
      res[images[f].link] = { prev, next };
    }
    return res;
  }
  