import React from 'react';
import styles from './About.module.scss'
import mumheadshot from './mumheadshot.png';
import { motion } from "framer-motion"
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import mumrecord from './mumrecord.png';

const products = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const About = () => (
  <motion.div exit={{ opacity: 0 }}>
    <motion.section
      variants={products}
      className="text-gray-700 body-font"
    >
      <div className={styles.container}>
      </div>
      <Row>
      <Col  sm={6} md={6} lg={4}>
        <div className={styles.mumrecordcontainer2}>
          <img className={styles.mumrecord2} src={mumheadshot}></img>
        </div>
      </Col>
      </Row>
      <div className={styles.container}>
        <h1 className={styles.header}>about me</h1>
        
        <h2 className={styles.education}>education</h2>
        <div className={styles.containers}>
          <d1>BA Fine Art & History of Art</d1>
          <d2>Goldsmiths University of London</d2>
          <d4>City Lit London</d4>
          <d5>Law Society Finals College of Law</d5>
        </div>
      </div>
      <div>
            <div className={styles.mumrecordcontainer}>
    <img sm={12} md={6} lg={4}className={styles.mumrecord} src={mumrecord}></img>
    </div>
                <Container className={styles.formContainer}>
                    <h1 className={styles.contactMe}>contact me</h1>
                    <Form action="https://apps.coodash.com/feedback/susanna" method="post">
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control required name="fname" type="text" placeholder="Enter first name" />
                                </Col>
                                <Col>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control required name="lname" type="text" placeholder="Enter last name" />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control required name="email" type="email" placeholder="Enter email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control required name="message" as="textarea" rows={3} />
                        </Form.Group>
                        <Form.Control name="redirect" type="hidden" value="http://localhost:3000/?#/thanku" />
                        <Button className={styles.button} variant="secondary" type="submit">
                            Send
                        </Button>
                    </Form>
                </Container>
            </div>
    </motion.section>
  </motion.div>

)

