import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Thanku.module.scss'
import { motion } from "framer-motion"
import Container from 'react-bootstrap/Container';

const products = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    },
};

export const Thanku = () => (
    <motion.div exit={{ opacity: 0 }}>
        <motion.section
            variants={products}
            className="text-gray-700 body-font"
        >
            <div>
                <Container className={styles.headerContainer}>
                    <h1 className={styles.header}>Thank you for your message!</h1>
                </Container>
                <Container className={styles.buttonContainer}>
                    <Link className={styles.button}  to="/">
                        back to home
                    </Link>
                </Container>
            </div>
        </motion.section>
    </motion.div>

)