import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import SideBar from './components/SideBar';
import styles from './App.module.scss'
import { Welcome2 } from './Welcome2';
import { Link } from 'react-router-dom';
import { About } from './About';
import { Gallery } from './Gallery';
import { Contact } from './Contact';
import { Images } from './Images';
import { Thanku } from './Thanku';
import { Exhibitions } from './Exhibitions';
import { NoMatch } from './NoMatch';
import { HashRouter, Route, Switch, useLocation } from "react-router-dom";
import { NavigationBar } from './components/NavigationBar';
import Footer from './components/Footer'
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <React.Fragment>
      <HashRouter
        hashType="slash"
      >
        <AnimatePresence exitBeforeEnter>
          <div className={styles.scrollableArea}>
            <Switch>
            <Route exact path="/"  >
              </Route>
              <Route path="/" >
                <NavigationBar />
              </Route>
            </Switch>
            <Switch>
              <Route exact path="/" component={Welcome2} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/about" component={About} />
              <Route path="/exhibitions" component={Exhibitions} />
              <Route path="/contact" component={Contact} />
              <Route path="/thanku" component={Thanku} />
              <Route path="/images/:id" component={Images} />
              <Route path="/images" component={Images} />
              <Route component={NoMatch} />
            </Switch>
            <Footer/>
          </div>
        </AnimatePresence>
      </HashRouter>
    </React.Fragment>

  );
}

export default App;
