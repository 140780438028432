import React from "react";
import styles from "./Images.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

import { getImages, getLinks, getFirst } from "./ImageData";

export function Images() {
  let { id } = useParams();

  const imageData = getImages();
  const links = getLinks();

  if (id == null) {
    id = getFirst();
  }

  function getImage(id) {
    return imageData[id].image;
  }
  function getPrevious(id) {
    return links[id].prev;
  }
  function getNext(id) {
    return links[id].next;
  }
  function getTitle(id) {
    return imageData[id].title;
  }
  
  return (
    <div>
      <Row className={styles.Row}>
        <Col className={styles.colLeft}>
          <Link className={styles.leftArrow} to={"/images/" + getPrevious(id)}>
            <AiOutlineArrowLeft classNamFe={styles.arrows} />
          </Link>
        </Col>
        <Col className={styles.fullPage}>
          <img className={styles.image} src={getImage(id)}></img>
          <div className={styles.titleText}>{getTitle(id)}</div>
        </Col>
        <Col className={styles.colRight}>
          <Link className={styles.rightArrow} to={"/images/" + getNext(id)}>
            <AiOutlineArrowRight className={styles.arrows} />
          </Link>
        </Col>
      </Row>
      <Row className={styles.gallery}>
        {Object.values(getImages()).map((item) => (
          <Link to={"/images/" + item.link}>
            <img className={styles.galleryView} src={item.image}></img>
          </Link>
        ))}
      </Row>
    </div>
  );
}
