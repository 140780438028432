import React from 'react';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { CgCopyright } from 'react-icons/cg';
import styles from './welcomeFooter.module.scss';
import { GrInstagram } from 'react-icons/gr';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export const welcomeFooter = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.Wrap}><div>
                <Container className={styles.footerContainer}>
                    <a className={styles.SocialIconLink} href="//www.instagram.com/wilkes.and.weaver" target="_blank" aria-label="Instagram">
                        <GrInstagram />
                    </a>
                    <a className={styles.SocialIconLink} href="//www.twitter.com/susannabyrne" target="_blank" aria-label="Twitter">
                        <FaTwitter />
                    </a>
                    <a className={styles.SocialIconLink} href="//www.linkedin.com/in/susanna-byrne-2394242b" target="_blank" aria-label="Instagram">
                        <FaLinkedin />
                    </a>
                </Container>
            </div>
            </div>
        </div>

    )
}

export default welcomeFooter
