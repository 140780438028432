import React from "react";
import styles from "./Exhibitions.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Print1 from "./Print1.png";
const exhibitions = [
  {
    title: "2021 Woolwich Contemporary Print Fair, London",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "2019 A Letter in Mind, Oxo Tower, London ",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "  2018 Composition, Lichfield Festival, Lichfield Cathedral",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "2017 Multi-Story, wilkes + weaver London",
    sm: 12,
    md: 12,
    lg: 12,
  },
  {
    title: "2016 Open, wilkes + weaver",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "2012 Open Studios, Westland Place Studios",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "2008, Circuit Wisely, Shoreditch Town Hall",

    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "2009, X Presents... Heart of Dixie UK, Goldsmiths Degree Show",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title:
      "2010 Circuit Wisely, Secret Location Dalston, London, Group Show, Silent City, Part 1, The Rag Factory, London",
    sm: 12,
    md: 12,
    lg: 12,
  },
];

const education = [
  {
    title: "Goldsmiths University of London, BA Fine Art & History of Art",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "Art Foundation,  City Lit London",
    sm: 12,
    md: 12,
    lg: 12,
  },

  {
    title: "Law Society Finals College of Law, Chancery Lane, London ",
    sm: 12,
    md: 12,
    lg: 12,
  },
];

export const Exhibitions = (props) => (
  <div>
    <Container className={styles.gallery}>
      <Row>
        <Row>
          <Col sm={4} md={8} lg={16}>
            <div className={styles.container}>
              <img className={styles.Image} src={Print1}></img>
              <div className={styles.Print1}>Untitiled (Tuile) 2021</div>
            </div>
          </Col>
        </Row>
        <Row>
        <h1 className={styles.header}>exhibitions</h1>
        {exhibitions.map((item) => (
          <Col sm={item.sm} md={item.md} lg={item.lg}>
            <Container className={styles.textcontainers}>
              <div className={styles.title}>{item.title}</div>
            </Container>
          </Col>
        ))}
        <h1 className={styles.header}>education</h1>
        {education.map((item) => (
          <Col sm={item.sm} md={item.md} lg={item.lg}>
            <Container className={styles.textcontainers}>
              <div className={styles.title}>{item.title}</div>
            </Container>
          </Col>
        ))}
      </Row>
      </Row>
    </Container>
  </div>
);
