import React from "react";
import styles from "./Welcome2.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from "./images/shadows.png";
import { motion } from "framer-motion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Footer from "./components/welcomeFooter";

export const Welcome2 = (props) => (
  <div>
    <img className={styles.coverImage} alt="Skippy" src={image}></img>
    <div className={styles.coverContent}>
      <Link className={styles.header} to="/">
        susanna byrne
      </Link>
      <div className={styles.BtnWrap}>
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={6} md={5} lg={4}>
              <Link className={styles.Button} to="/images">
                gallery
              </Link>
            </Col>
            <Col sm={6} md={5} lg={4}>
              <Link className={styles.Button} to="/exhibitions">
                exhibitions / cv
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  </div>
);
